'use client';

import { MantineColorsTuple, createTheme } from '@mantine/core';

const avraColor: MantineColorsTuple = [
  '#e3faff',
  '#d2effa',
  '#aadcf0',
  '#7ec9e6',
  '#5ab9dd',
  '#42aed8',
  '#32a9d7',
  '#1e94bf',
  '#0584ab',
  '#007298'
];

const avraColor2: MantineColorsTuple = [
  "#f0f2fa",
  "#dde1ee",
  "#b7bfde",
  "#8f9ccf",
  "#6e7dc3",
  "#596bbc",
  "#4e61ba",
  "#3f51a3",
  "#364893",
  "#2b3e82"
];

const avraColor3: MantineColorsTuple = [
  "#e1fefa",
  "#d2f8f0",
  "#aaede0",
  "#80e2cf",
  "#5cd9c1",
  "#44d3b7",
  "#33d0b3",
  "#1fb99d",
  "#08a48b",
  "#008e77"
];

export const theme = createTheme({
  primaryColor: 'avraColor',
  colors: {
    avraColor, avraColor2, avraColor3
  }
});
