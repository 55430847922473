import { Container, Loader, Skeleton } from "@mantine/core";

export default function HeaderSkeleton() {
    // You can add any UI inside Loading, including a Skeleton.
    return (

        <Container mt={100} mih={700}>
            <Skeleton height={50} circle mb="xl" />
        </Container>
    )
}