"use client"
import { Avatar, Button, Group, Menu, Text, UnstyledButton, rem, useMantineTheme } from "@mantine/core"
import { IconChevronDown, IconLogin } from "@tabler/icons-react"
import cx from 'clsx';
import { signIn, signOut, useSession } from "next-auth/react";

import {
    IconHeart,
    IconLogout,
} from '@tabler/icons-react';
import classes from './UserDropdown.module.css';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import Link from "next/link";



const User = () => {
    const [userMenuOpened, setUserMenuOpened] = useState(false);
    const theme = useMantineTheme();

    const router = useRouter();
    const { data: session } = useSession();

    return (
        <Menu
            width={260}
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
            withinPortal
        >
            <Menu.Target>
                <UnstyledButton
                    className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                >
                    <Group gap={7}>
                        {session && session.user ?
                            <>
                                <Avatar src={''} alt={session?.user.username} radius="xl" size={20} />
                                <Text fw={500} size="sm" lh={1} mr={3}>
                                    {session?.user.patient_name}
                                </Text>
                                <IconChevronDown style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                            </> :
                            <>
                                <Text fw={500} size="sm" lh={1} mr={3} onClick={() => signIn()}>
                                    SignUp
                                </Text>
                                <Text fw={500} size="sm" lh={1} mr={3} onClick={() => signIn()}>
                                    Login
                                </Text>
                            </>

                        }
                    </Group>
                </UnstyledButton>
            </Menu.Target>
            {session && session.user &&
                <Menu.Dropdown>


                    {/* <Menu.Label>Settings</Menu.Label> */}
                    {/* <Menu.Item
            leftSection={
                <IconSettings style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
            }
        >
            Account settings
        </Menu.Item> */}
                    {/* <Menu.Item
            leftSection={
                <IconSwitchHorizontal style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
            }
        >
            Change account
        </Menu.Item> */}


                    <Menu.Item
                        onClick={() => router.push("/user/dashboard")}
                    >
                        Dashboard
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => router.push("/user/reports")}
                    >
                        Reports
                    </Menu.Item>
                    <Menu.Item
                        leftSection={
                            <IconLogout style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                        }
                        onClick={() => signOut()}
                    >
                        Logout
                    </Menu.Item>
                    <Menu.Divider />

                    {/* <Menu.Label>Danger zone</Menu.Label> */}

                </Menu.Dropdown>
            }
        </Menu >
    )
}



export default User;