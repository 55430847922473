"use client"
import { Text, Container, ActionIcon, Group, rem } from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons-react';

import classes from './Footer.module.css';

const data = [
    {
        title: 'About',
        links: [
            { label: 'Clinical Tests', link: '/services/all-tests' },
            { label: 'Health Packages', link: '/services/healthpackages' },
            { label: 'Appointments', link: '/appointment' },
            { label: 'Contact Us', link: '/contactus' },
        ],
    },
    // {
    //     title: 'Popular Tests',
    //     links: [
    //         { label: 'Contribute', link: '#' },
    //         { label: 'Media assets', link: '#' },
    //         { label: 'Changelog', link: '#' },
    //         { label: 'Releases', link: '#' },
    //     ],
    // },
    {
        title: 'Community',
        links: [
            { label: 'Health Tips', link: '/healthtipsandresources' },
            { label: 'Testimonials', link: '/testimonials' }
        ],
    },
];

export function FooterLinks() {
    const groups = data.map((group) => {
        const links = group.links.map((link, index) => (
            <Text<'a'>
                key={index}
                className={classes.link}
                component="a"
                c={'white'}
                fw={300}
                href={link.link}

            >
                {link.label}
            </Text>
        ));

        return (
            <div className={classes.wrapper} key={group.title}>
                <Text c={'white'} fw={'bold'} className={classes.title}>{group.title}</Text>
                {links}
            </div>
        );
    });

    return (
        <footer className={classes.footer}>
            <Container className={classes.inner} size={'xl'}>
                <div className={classes.logo}>
                    {/* <MantineLogo size={30} /> */}
                    <Text size='xl' fw={600} c={'white'} component='h3'>Avra PathCare Pathology</Text>
                    <Text size="xs" c="white" className={classes.description}>
                        Trust in Truth
                    </Text>

                </div>
                <div className={classes.groups}>{groups}</div>
            </Container>
            <Container className={classes.afterFooter} size={'xl'}>
                <Text c="white" size="sm">
                    © 2023 AvraPathCare. All rights reserved.
                </Text>

                <Group gap={0} className={classes.social} justify="flex-end" wrap="nowrap">
                    <ActionIcon size="lg" color="white" variant="subtle">
                        <IconBrandTwitter style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
                    </ActionIcon>
                    <ActionIcon size="lg" color="white" variant="subtle">
                        <IconBrandYoutube style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
                    </ActionIcon>
                    <ActionIcon size="lg" color="white" variant="subtle">
                        <IconBrandInstagram style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
                    </ActionIcon>
                    <Text size='xs' component='a' c={'white'} title="Privacy Policy" href="/privacypolicy">
                        Privacy Policy
                    </Text>
                </Group>
            </Container>
        </footer>
    );
}