import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { testReducer } from './slices/testsSlice'
import { cartReducer } from './slices/cartSlice';
import { persistReducer } from "redux-persist";
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

const createNoopStorage = () => {
    return {
        getItem(_key: any) {
            return Promise.resolve(null);
        },
        setItem(_key: any, value: any) {
            return Promise.resolve(value);
        },
        removeItem(_key: any) {
            return Promise.resolve();
        },
    };
};
const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();


const cartPersistConfig = {
    key: "cart",
    storage: storage,
    whitelist: ["cartState"],
};

const rootReducer = combineReducers({
    cart: persistReducer(cartPersistConfig, cartReducer),
    tests: testReducer
});

export const store =
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ serializableCheck: false }),
    })

// Infer the type of makeStore
export type AppStore = ReturnType<typeof configureStore>
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;