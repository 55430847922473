"use client"
import {
    HoverCard,
    Group,
    Button,
    UnstyledButton,
    Text,
    SimpleGrid,
    ThemeIcon,
    Anchor,
    Divider,
    Center,
    Box,
    Burger,
    Drawer,
    Collapse,
    ScrollArea,
    rem,
    Badge,
    Autocomplete,
} from '@mantine/core';

import { useDisclosure } from '@mantine/hooks';

import {
    IconVaccine,
    IconTestPipe,
    IconChevronDown,
    IconBox,
    IconCamera,
    IconShoppingCart,
    IconSearch,
} from '@tabler/icons-react';
import classes from './Header.module.css';
import User from '../UserDropdown/UserDropdown';
import HeaderSkeleton from './loading';
import UserMobile from '../UserDropdown/UserDropdownMobile';

import { useAppSelector } from '@/utils/lib/redux/hooks';
import { usePathname, useRouter } from 'next/navigation';
import Link from 'next/link';
import { Suspense } from 'react';


const mockdata = [
    {
        icon: IconVaccine,
        title: 'Clinical Laboratory Tests',
        description: 'Regular tests for regular health checkups',
        url: '/services/all-tests'
    },
    {
        icon: IconBox,
        title: 'Health Packages',
        description: 'Multiple checkups for all round health',
        url: '/services/healthpackages'
    },
    // {
    //     icon: IconTestPipe,
    //     title: 'Test by Organs',
    //     description: 'Specialised tests to get in-depth insights',
    //     url: '/services/test-by-organs'
    // },

];


export function HeaderTabs() {

    const path = usePathname();
    const router = useRouter();

    const cartData = useAppSelector((state) => state.cart.cartState);

    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);

    const popular_tests_for_search = [
        "ACTH (ADRENO CORTICOTROPIC HORMONE) PLASMA",
        "ACETYLECHOLINE RECEPTOR BINDING ANTIBODY, SERUM",
        "ACID PHOSPHATASE TOTAL SERUM",
        "AFB (ACID FAST BACILLI) STAIN",
        "ALPHA FETO PROTEIN (AFP), MATERNAL SERUM",
        "ALPHA FETO PROTEIN (AFP), CANCER MARKER SERUM",
        "ALDOSTERONE, SERUM",
        "AMYLASE",
        "AMMONIA - PLASMA- Na. HEPARIN (SODIUM HEPARIN)",
        "ANGIOTENSIN CONVERTING ENZYME (ACE), SERUM",
        "CARDIOLIPIN ANTIBODIES IGG, SERUM",
        "CARDIOLIPIN ANTIBODIES IGM, SERUM",
        "ANTI CYCLIC CITRULLINATED PEPTIDE(ANTI CCP),SERUM",
        "ANTI NUCLEAR ANTIBODY \/ FACTOR, IFA, SERUM",
        "ANA by ELISA (Anti Nuclear Antibody) screening, Serum",
        "ANTI STREPTOLYSIN O (ASO) QUANTITATIVE,SERUM",
        "ABNORMAL HEMOGLOBIN SCREEN (HB HPLC), BLOOD",
        "ADENOSINE DEAMINASE, FLUID (ADA)",
        "AMENORRHOEA SCREEN ( LH, FSH, PROLACTIN)",
        "APOLIPOPROTEIN- A1, SERUM",
        "APOLIPOPROTEIN- B, SERUM",
        "APTT PLASMA",
        "ANTI MULLERIAN HORMONE(AMH), SERUM",
        "THYROID PEROXIDASE ANTIBODIES (ANTI TPO) , SERUM",
        "ANTI THYROID ANTIBODIES PANEL (ANTI TG, ANTI TPO)",
        "B-TYPE NATRIURETIC PEPTIDE, (BNP) PLASMA",
        "BETA HCG, TOTAL, SERUM",
        "BLEEDING TIME & CLOTTING TIME",
        "BLOOD CULTURE - BACTEC",
        "BLOOD GROUP (ABO & RH)",
        "BODY FLUID CULTURE ( EXCEPT CSF )",
        "BLOOD SUGAR FASTING (GLUCOSE FAST. FBS)",
        "BLOOD SUGAR PP (GLUCOSE PP)",
        "BLOOD SUGAR RANDOM (GLUCOSE RANDOM)",
        "BLOOD SUGAR (FASTIN \/ PP)",
        "CERULOPLASMIN LEVEL, SERUM",
        "COMPLEMENT C3 & C4 SERUM",
        "CALCIUM IONISED, SERUM",
        "CALCIUM 24 HRS URINE",
        "COMPLETE BLOOD COUNT (CBC)",
        "CB NAAT (FOR MTB)",
        "Complete Haemogram with PS",
        "C-REACTIVE PROTEIN (QUANTITATIVE), SERUM",
        "CULTURE AEROBIC & SUSCEPTIBILITY, SPUTUM",
        "CULTURE, AEROBIC & SUSCEPTIBILITY, MISCELLANEOUS",
        "CULTURE AEROBIC & SUSCEPTIBILITY, CSF",
        "CULTURE, AEROBIC & SUSCEPTIBILITY,THROAT SWAB",
        "CULTURE,  AEROBIC & SUSCEPTIBILITY, STOOL",
        "CA-125, CANCER MARKER, SERUM",
        "CARCINO EMBRYONIC ANTIGEN (CEA), SERUM",
        "CORTISOL MORNING, SERUM",
        "CORTISOL EVENING, SERUM",
        "CA72.4, SERUM",
        "CD10 (CALLA)",
        "CA - 15.3, CANCER MARKER, SERUM",
        "CA-19.9, CANCER MARKER, SERUM",
        "C-PEPTIDE, SERUM",
        "COOMBS DIRECT (DCT)",
        "COOMBS INDIRECT (ICT)",
        "COVID ANTIGEN",
        "COVID RT PCR TEST",
        "CENTRAL LINE TIP CULTURE",
        "CREATININE CLEARANCE, SERUM & URINE",
        "CREATINE PHOSPHOKINASE-MB,SERUM (CPKMB)",
        "CREATINE KINASE (CK- NAC), SERUM",
        "D-DIMER SEMI QUANTITATIVE",
        "DENGUE COMBO SCREEN, RAPID, SERUM",
        "DENGUE NS1 ANTIGEN, RAPID, SERUM",
        "DENGUE (RNA DETECTION) QUALITATIVE",
        "DHEA-SULPHATE, SERUM",
        "DOUBLE MARKER TEST, SERUM",
        "ESTRADIOL (E2) , SERUM",
        "ELECTROLYTES , SERUM (Na, K & CL)",
        "ERYTHROCYTE SEDIMENTATION RATE, BLOOD",
        "FNAC (FINE NEEDLE ASPIRATION CYTOPATHOLOGY)"
    ];

    const handleOptionSubmit = (selectedOption: string) => {
        router.push(`/search?q=${selectedOption}`)
    }
    const handleChange = (seaarhcInput: any) => {
        debugger
    }

    const autocomplete = <Autocomplete
        classNames={{ dropdown: classes.dropdown }}
        w={450}
        fz={10}
        className={classes.search}
        rightSection={<IconSearch style={{ width: rem(18), height: rem(18) }} stroke={1.5} />}
        placeholder="Search lab tests "
        data={popular_tests_for_search}
        maxDropdownHeight={200}
        onChange={((value: string) => handleChange(value))}
        onOptionSubmit={(value: string) => handleOptionSubmit(value)}
    />;

    const links = mockdata.map((item) => (
        <UnstyledButton className={classes.subLink} fz={12} key={item.title} onClick={() => router.push(item.url)} >
            <Group wrap="nowrap" align="flex-start">
                <ThemeIcon size={30} variant="default" radius="md">
                    <item.icon style={{ width: rem(22), height: rem(22) }} />
                </ThemeIcon>
                <div>
                    <Text size="sm" fw={500}>
                        {item.title}
                    </Text>
                    <Text size="xs" c="dimmed">
                        {item.description}
                    </Text>
                </div>
            </Group>
        </UnstyledButton>
    ));

    return (
        <Suspense fallback={<HeaderSkeleton />}>
            <Box pb={10}>
                <div className={classes.preheader}>
                    <Group justify="space-around" >

                        <Text size='sm' c={'white'}>Mon to Sun - 24/7</Text>
                        <Text size='sm' c={'white'}>info@avrapathcare.com | 9876543210</Text>
                    </Group>
                </div>

                <header className={classes.header}>
                    <Group justify="space-between" h="100%">
                        <Text size='xl' component='a' href='/' fw={500} c='black'>Avra PathCare Pathology</Text>
                        {autocomplete}
                        <Group h="100%" gap={0} visibleFrom="sm">
                            <Link href="/" className={`${classes.link} ${path == "/" ? classes.active : ""}`}>
                                Home
                            </Link>
                            <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                                <HoverCard.Target>
                                    <Link href="#" className={`${classes.link} ${path == "/services" ? classes.active : ""}`}>
                                        <Center inline>
                                            <Box component="span" mr={5}>
                                                Services
                                            </Box>
                                            <IconChevronDown
                                                style={{ width: rem(16), height: rem(16) }}

                                            />
                                        </Center>
                                    </Link>
                                </HoverCard.Target>

                                <HoverCard.Dropdown style={{ overflow: 'hidden' }}>
                                    <Group justify="space-between" px="md">
                                        <Text fw={500}>Services</Text>
                                        <Anchor href="/services/all-tests" fz="xs">
                                            View all
                                        </Anchor>
                                    </Group>

                                    <Divider my="sm" />

                                    <SimpleGrid cols={2} spacing={0}>
                                        {links}
                                    </SimpleGrid>

                                    <div className={classes.dropdownFooter}>
                                        <Group justify="space-between">
                                            <div>
                                                <Text fw={500} fz="sm">
                                                    Can't find your test?
                                                </Text>
                                                <Text size="xs" c="dimmed">
                                                    Send us your prescription, we will handle the rest.
                                                </Text>
                                            </div>
                                            <Button variant="default" onClick={() => router.push('/appointment')}>Upload Prescription</Button>
                                        </Group>
                                    </div>
                                </HoverCard.Dropdown>
                            </HoverCard>
                            <Link href="/aboutus" className={`${classes.link} ${path == "/aboutus" ? classes.active : ""}`}>
                                About Us
                            </Link>
                            <Link href="/appointment" className={`${classes.link} ${path == "/appointment" ? classes.active : ""}`}>
                                Appointment
                            </Link>
                            <Link href="/healthtipsandresources" className={`${classes.link} ${path == "/healthtipsandresources" ? classes.active : ""}`}>
                                Health Tips
                            </Link>
                            {/* <Link href="/testimonials" className={`${classes.link} ${path == "/testimonials" ? classes.active : ""}`}>
                            Testimonials
                        </Link> */}

                        </Group>

                        <Group visibleFrom="sm">
                            <UnstyledButton onClick={() => router.push('/home/cart')}>
                                <IconShoppingCart />
                                {cartData.length > 0 && <Badge >{cartData.length}</Badge>}

                            </UnstyledButton>
                            <User />
                        </Group>
                        <Group hiddenFrom="sm">
                            <UnstyledButton onClick={() => router.push('/home/cart')}>
                                <IconShoppingCart />
                                {cartData.length > 0 && <Badge >{cartData.length}</Badge>}
                            </UnstyledButton>
                            <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
                        </Group>


                    </Group>

                </header>
                {/* <Group visibleFrom='xs'>
                    <UnstyledButton onClick={() => router.push('/home/cart')}>
                        <IconShoppingCart />
                        <Badge >{cartData.length}</Badge>

                    </UnstyledButton>
                </Group> */}

                <Drawer
                    opened={drawerOpened}
                    onClose={closeDrawer}
                    size="100%"
                    padding="md"
                    title="Navigation Menu"
                    hiddenFrom="sm"
                    zIndex={1000000}
                >
                    <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
                        <Divider my="sm" />
                        {autocomplete}
                        <Link href="/" className={`${classes.link} ${path == "/" ? classes.active : ""}`}>
                            Home
                        </Link>
                        <UnstyledButton className={`${classes.link} ${path == "/services/all-tests" ? classes.active : ""}`} onClick={toggleLinks}>
                            <Center inline>
                                <Box component="span" fz={12} mr={5}>
                                    Services
                                </Box>
                                <IconChevronDown
                                    style={{ width: rem(16), height: rem(16) }}

                                />
                            </Center>
                        </UnstyledButton>
                        <Collapse in={linksOpened}>{links}</Collapse>
                        <Link href="/aboutus" className={`${classes.link} ${path == "/aboutus" ? classes.active : ""}`}>
                            About Us
                        </Link>
                        <Link href="/appointment" className={`${classes.link} ${path == "/appointment" ? classes.active : ""}`}>
                            Appointment
                        </Link>
                        <Link href="/healthtipsandresources" className={`${classes.link} ${path == "/healthtipsandresources" ? classes.active : ""}`}>
                            Health Tips
                        </Link>
                        {/* <Link href="/testimonials" className={`${classes.link} ${path == "/healthtipsandresources" ? classes.active : ""}`}>
                        Testimonials
                    </Link> */}

                        <Divider my="sm" />

                        <Group justify="center" grow pb="xl" px="md">
                            <UserMobile />
                        </Group>
                    </ScrollArea>
                </Drawer>
            </Box>
        </Suspense>


    );
}