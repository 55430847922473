"use client"
import { Avatar, Button, Group, Menu, Text, UnstyledButton, rem, useMantineTheme } from "@mantine/core"
import { IconChevronDown, IconLogin } from "@tabler/icons-react"
import cx from 'clsx';
import { signIn, signOut, useSession } from "next-auth/react";

import {
    IconHeart,
    IconLogout,
} from '@tabler/icons-react';
import classes from './UserDropdown.module.css';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import Link from "next/link";





const UserMobile = () => {
    const [userMenuOpened, setUserMenuOpened] = useState(false);
    const theme = useMantineTheme();

    const router = useRouter();
    const { data: session } = useSession();

    return (

        <Group gap={7}>
            {session && session.user ?
                <>
                    <Avatar src={''} alt={session?.user.username} radius="xl" size={20} />
                    <Text fw={500} size="sm" lh={1} mr={3}>
                        {session?.user.patient_name}
                    </Text>
                    {/* <IconChevronDown style={{ width: rem(12), height: rem(12) }} stroke={1.5} /> */}
                    <Link className={classes.link}
                        href={"/user/dashboard"}
                    >
                        Dashboard
                    </Link>

                    <Button className={classes.link} onClick={() => signOut()} >
                        <IconLogout style={{ width: rem(16), height: rem(16) }} stroke={1.5} />  Logout
                    </Button>
                </> :
                <Text fw={500} size="sm" lh={1} mr={3} onClick={() => router.push('/login')}>
                    Login
                </Text>
            }
        </Group>
    )
}

export default UserMobile;