import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
export interface ITestState {
    testState: [];
}

const initialState: ITestState = {
    testState: [],
};

export const testSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setTestState: (state, action: PayloadAction<[]>) => {
            state.testState = action.payload;
        },
    },
});

export const { setTestState } = testSlice.actions;
export const testReducer = testSlice.reducer;