'use client'
import { useRef } from 'react'
import { Provider } from 'react-redux'
import { store } from '../utils/lib/redux/store';
import { persistStore } from "redux-persist";


export default function StoreProvider({
    children
}: {
    children: React.ReactNode
}) {
    const storeRef = useRef<any>()

    if (!storeRef.current) {
        // Create the store instance the first time this renders
        storeRef.current = store
        persistStore(storeRef.current)
    }

    return <Provider store={storeRef.current}>{children}</Provider>
}