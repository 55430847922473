import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
export interface ICartState {
    cartState: any[];
}

const initialState: ICartState = {
    cartState: [],
};

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setCartState: (state, action: PayloadAction<any[]>) => {
            state.cartState = action.payload;
        },
        resetCart: () => initialState
    },
});

export const { setCartState, resetCart } = cartSlice.actions;
export const cartReducer = cartSlice.reducer;